<template>

  <div id="form-transporte">
    <div class="modal-header">
      <h1  class="modal-title font-main-bold color-main fs-5">
        {{ accion }}
      </h1>
      <button 
        type="button" 
        class="modal-custom-close" 
        data-bs-dismiss="modal" 
        @click="closeModal()"
        aria-label="Close">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="modal-body">

      <div class="row">
        <div class="col-12">
          <label class="text-secondary">
            Tipo de transporte
          </label>
          <v-select
            class="selvue-custom mb-3"
            :class="userInput.tipo.error ? 'form-error' : ''"
            placeholder="Seleccionar"
            v-model="userInput.tipo.value"
            :options="tipo_list"
            @input="selectTipo(userInput.tipo.value)"
            :clearable="false"
            >
          </v-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6 mb-3">
          <label class="text-secondary">
            Nombre de transporte
          </label>
          <input
            class="form-control input-custom"
            :class="userInput.nombreMedio.error ? 'form-error' : ''"
            maxlength="30"
            type="text"
            v-model="userInput.nombreMedio.value"
            />
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <label class="text-secondary">
            Capacidad máxima
          </label>
          <input
            class="form-control input-custom"
            placeholder="Pasajeros totales"
            :class="userInput.cantidadAsientos.error ? 'form-error' : ''"
            maxlength="30"
            type="text"
            v-model="userInput.cantidadAsientos.value"
            />
        </div>
      </div>
      <Spinner v-if="show_spinner" />
    </div>
  </div>

</template>

<script>

import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";

export default {
  components: { Spinner },

  data() {
    return {
      error: false,
      show_spinner: false,
      accion: "Crear transporte",
      tipo_list: ['Aéreo', 'Terrestre'],

      userInput: {
        tipo: {
          value: "",
          error: false
        },
        nombreMedio: {
          value: "",
          error: false
        },
        cantidadAsientos: {
          value: "",
          error: false
        },
        idTipo: {
          value: ""
        },
        idMedio: {
          value: ""
        }
      }
    };
  },

  watch: {
    userInput: {
      handler(val) {
        this.$emit("userInputChange", val);
      },
      deep: true
    }
  },

  computed: {
    ...mapState("transporteModule", ["editingTransporte"]),
  },

  created() {
    this.$emit("userInputChange", this.userInput);

    if(Object.keys(this.editingTransporte).length !== 0) {
      this.setDataForEditingTransporte();
    }
  },

  methods: {
    ...mapActions("transporteModule", ["cleanEditingTransporteAction"]),

    setDataForEditingTransporte() {
      this.userInput.tipo.value = this.editingTransporte.transporte_tipo.nombre_transporte;
      this.accion = "Edita transporte";
      if (this.userInput.tipo.value == 'Aéreo') this.userInput.idTipo.value = '2';
      if (this.userInput.tipo.value == 'Terrestre') this.userInput.idTipo.value = '1';
      this.userInput.nombreMedio.value = this.editingTransporte.nombre_medio;
      this.userInput.cantidadAsientos.value = this.editingTransporte.cantidad_asiento;
      this.userInput.idMedio.value = this.editingTransporte.id_medio;
    },

    closeModal() {
      this.cleanEditingTransporteAction();
      this.$emit("close-modal");
    },

    selectTipo(tipo){
      if(tipo == "Aéreo") {
        this.userInput.idTipo.value = "2";
      }
      if(tipo == "Terrestre") {
        this.userInput.idTipo.value = "1";
      }
    },
  },
};
</script>
