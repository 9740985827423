<template>
  <section id="transportes">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-bus-simple color-secondary"></i>
          En esta seccion podras visualizar los transportes creados o crear nuevos
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button
          @click="open_modal = true"
          data-bs-toggle="modal" 
          data-bs-target="#modal-form-transporte"
          v-if="transportes_list.length > 0"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear transporte
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <TransporteTable 
          :transportes="transportes_list"
          @open-modal-create="open_modal = true"
          @open-modal-edit="openModalForEdit()"
        />
      </div>
    </div>

  
  <!-- Modal -->
  <div 
    class="modal modal-custom fade" 
    id="modal-form-transporte" 
    tabindex="-1" 
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <CrearTransporte 
          v-if="open_modal === true"
          @userInputChange="onUserInputChange"
          @close-modal="closeModal()" 
        />

        <div class="modal-footer">
          <button 
            type="button" 
            ref="closeModal"
            class="btn btn-custom-color-white rounded-pill mw-100" 
            @click="closeModal()"
            data-bs-dismiss="modal">
            <i class="fa-solid fa-angle-left pe-1"></i>
            Volver
          </button>
          <button 
            type="button" 
            class="btn btn-custom-color-blue rounded-pill mw-100"
            @click="addOrEdit()">
            {{nameAction()}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <Status
    v-if="open_modal_status"
    :msg="modal_status_msg"
    :status="modal_status"
    @close="acceptStatus"
  />
  <Spinner v-if="show_spinner"/>
  </section>

</template>

<script>
import CrearTransporte from "./CrearTransporte.vue";
import TransporteTable from "./TransporteTable.vue";
import { mapActions, mapState } from "vuex";
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";

export default {
  components: {
    CrearTransporte,
    TransporteTable,
    Status,
    Spinner
  },

  data() {
    return {
      show_spinner: false,
      open_modal: false,
      editBool: false,

      modal_status_msg: "",
      modal_status: false,
      open_modal_status: false,

      userInput: {}
    };
  },

  computed: {
    ...mapState("transporteModule", ["transportes_list"]),
  },
  
  async created() {
    this.show_spinner = true;
    const data = {
      id_tipo: "0",
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
    };
    await this.getTransportesList(data);
    this.show_spinner = false;
  },


  methods: {
    ...mapActions("transporteModule", [
      "getTransportesList", 
      "createTransporteAction",
      "editTransporteAction",
      "cleanEditingTransporteAction"
    ]),

    addOrEdit() {
      if (this.validateInputs() == true) {
        if (this.editBool == true) {
          this.editTransporte();
          this.$refs.closeModal.click();
        }
        else {
          this.createTransporte();
          this.$refs.closeModal.click();
        }
      } else {
        this.toastTransporteInputValidation();
      }
    },

    nameAction() {
      if (this.editBool == true) return 'Guardar';
      else return 'Añadir';
    },

    async createTransporte() {
      this.show_spinner = true;
      const payload = {
        idTipo: this.userInput.idTipo.value,
        nombreMedio: this.userInput.nombreMedio.value,
        cantidadAsientos: this.userInput.cantidadAsientos.value,
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario
      };
      const newTransporte = await this.createTransporteAction(payload);
      this.show_spinner = false;
      this.open_modal = false;
      if (newTransporte) {
        this.clean();
        this.toastTransporteCreateSuccess();
      } else {
        this.modal_status = false;
        this.modal_status_msg = "Ha ocurrido un problema en la creación de transporte. Por favor inténtalo nuevamente.";
        this.open_modal_status = true;
      }
    },

    async editTransporte() {
      this.show_spinner = true;
      const payload = {
        idMedio: this.userInput.idMedio.value,
        idTipo: this.userInput.idTipo.value,
        nombreMedio: this.userInput.nombreMedio.value,
        cantidadAsientos: this.userInput.cantidadAsientos.value,
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario
      };
      const editedTransporte = await this.editTransporteAction(payload);
      this.show_spinner = false;
      this.open_modal = false;
      if (editedTransporte) {        
        this.clean();
        this.toastTransporteEditSuccess();
      } else {
        this.modal_status = false;
        this.modal_status_msg = "Ha ocurrido un problema al editar el transporte. Por favor inténtalo nuevamente.";
        this.open_modal_status = true;        
      }
    },

    // Modal status
    acceptStatus(){
      this.open_modal_status = false;
      this.modal_status_msg = "";
    },

    clean() {
      this.cleanEditingTransporteAction();
      this.editBool = false;
      this.userInput = {};
    },

    onUserInputChange(userInputObj) {
      this.userInput = userInputObj;
    },

    closeModal(){
      this.clean();
      this.open_modal = false;
    },

    openModalForEdit() {
      this.editBool = true;
      this.open_modal = true;
    },

    validateInputs(){
      ["idTipo", "nombreMedio", "cantidadAsientos"].forEach(field => {
        this.userInput[field].error = this.userInput[field].value === '' ? true : false;
      });

      if (Object.values(this.userInput).some(val => val.error)) {
        return false;
      } else {
        return true;
      }
    },

    toastTransporteCreateSuccess() {
      this.$toast.open({
        message: "Tu transporte ha sido creado correctamente.",
        type: 'success',
        duration: 6000,
        position: 'top-right'
      });
    },
    toastTransporteEditSuccess() {
      this.$toast.open({
        message: "Tu transporte ha sido actualizado correctamente.",
        type: 'success',
        duration: 6000,
        position: 'top-right'
      });
    },
    toastTransporteInputValidation() {
      this.$toast.open({
        message: 'Por favor completa los campos obligatorios.',
        type: 'error',
        duration: 5000,
        position: 'top-right'
      });
    }

  },
};
</script>
