<template>

<div class="table-container">
    <table class="table table-custom table-borderless table-custom-responsive mb-0">
      <thead>
        <tr>
          <th scope="col">Tipo de transporte</th>
          <th scope="col">Nombre transporte</th>
          <th scope="col">Capacidad máxima</th>
          <th scope="col" class="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(t, i) in transportes" :key="i">
          <td class="th th-tipo-transporte">
            {{ t.transporte_tipo.nombre_transporte }}
          </td>
          <td class="th th-nombre-transporte">
            {{ t.nombre_medio }}
          </td>
          <td class="th th-capacidad-maxima">
            {{ t.cantidad_asiento }}
          </td>
          <td class="th th-accion text-center">
            <div class="dropdown dropdown-custom d-flex justify-content-center">
                <button 
                  class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                  type="button"
                  data-bs-toggle="dropdown">
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </button>
                <ul 
                  class="dropdown-menu"
                >
                  <li>
                    <a 
                      @click="openEditModal(t)"
                      data-bs-toggle="modal" 
                      data-bs-target="#modal-form-transporte"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                      Editar
                    </a>
                  </li>
                  <li>
                    <a 
                      @click="deleteTransporte(t)"
                      class="dropdown-item" 
                      href="javascript:">
                      <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                      Eliminar
                    </a>
                  </li>
                </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="transporte-container__no-data" v-if="transportes.length==0">
      <img
        class="transporte-container__img-question"
        src="@/assets/img/utilities/question_face.svg"
        alt=""
      />
      <p>Aún no se ha registrado ningún transporte</p>
      <button 
        class="tramos-add__boton" 
        @click="openCreateModal()"
        data-bs-toggle="modal" 
        data-bs-target="#modal-form-transporte"
      >+ Crear transporte</button>
    </div>
    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="acceptStatus"
    />
    <Spinner v-if="show_spinner" />
</div>

</template>

<script>
import { mapActions } from "vuex";
import Question from "../Modales/Question.vue";
import Spinner from "../Spinner.vue";
import Status from "../Modales/Status.vue";
export default {

  components: {
    Question, Spinner, Status
  },

  props: {
    transportes: {
      type: Array,
    },
  },

  data() {
    return {
      show_no_result: false,
      id_delete: '',
      open_question_modal: false,
      question_modal_msg: '',
      show_spinner: false,
      modal_status_msg: "",
      modal_status: false,
      open_modal_status: false,
    };
  },

  watch:{
    transportes() {
      if(this.transportes.length > 0) this.show_no_result = false;
      else this.show_no_result = true;
    }
  },

  methods: {
    ...mapActions("transporteModule", ["deleteTransporteAction", "setEditingTransporteAction"]),
    
    openCreateModal() {
      this.$emit("open-modal-create");
    },

    openEditModal(medio) {
      this.setEditingTransporteAction(medio);
      this.$emit("open-modal-edit");
    },

    // Eliminar transporte pregunta
    deleteTransporte(medio) {
      this.id_delete = medio.id_medio;
      this.question_modal_msg = "¿Estás seguro de eliminar este transporte?";
      this.open_question_modal = true;
    },

    // Eliminar transporte
    async acceptQuestion() {
      const payload = {
        idMedio: this.id_delete,
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario,
      };
      this.show_spinner = true;
      const deletedTransporte = await this.deleteTransporteAction(payload);
      this.show_spinner = false;
      this.open_question_modal = false;
      this.modal_status = false;
      if (deletedTransporte) {
        if (deletedTransporte.nombre_medio == 'error') {
          this.open_modal_status = true;
          this.modal_status_msg = 'No se pueden eliminar transportes asignados a tramos tramos.';
        } else {
          this.toastTransporteDeleteSuccess();
        }
      } else {
        this.open_modal_status = true;
        this.modal_status_msg = "Ha ocurrido un problema en la eliminación del transporte. Por favor inténtalo nuevamente.";
      }
    },

    // Cancela eliminar
    cancelQuestion() {
      this.open_question_modal = false;
    },

    // Termina eliminar
    acceptStatus(){
      this.open_modal_status = false;
    },

    toastTransporteDeleteSuccess() {
      this.$toast.open({
        message: 'El transporte ha sido eliminado correctamente.',
        type: 'success',
        duration: 6000,
        position: 'top-right'
      }); 
    }
  },
};
</script>

<style></style>
